<template>
  <div>
    <b-row>
      <b-col cols="10">
        <div class="areawrap">
          <b-row>
            <b-col cols="9">
              <template v-if="step === 1">
                <h2 class="px-4">
                  Customer Information
                </h2>
                <hr>
                <div class="px-4">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Clinic Name"
                        class="mb-6"
                        :state="$v.form.$anyDirty ? !$v.form.clinic_name.$anyError : null"
                      >
                        <b-form-input
                          v-model="form.clinic_name"
                          placeholder="Enter Clinic Name"
                        />
                        <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.clinic_name.required : null">
                          clinic name is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Street Address"
                        class="mb-6"
                        :state="$v.form.$anyDirty ? !$v.form.clinic_address.$anyError : null"
                      >
                        <b-form-input v-model="form.clinic_address" placeholder="Enter Address" />
                        <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.clinic_address.required : null">
                          Street address is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="City"
                        class="mb-6"
                        :state="$v.form.$anyDirty ? !$v.form.clinic_city.$anyError : null"
                      >
                        <b-form-input v-model="form.clinic_city" placeholder="Enter City" />
                        <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.clinic_city.required : null">
                          city is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <div class="mb-4">
                        <b-form-group
                          label="Country"
                          class="mb-6"
                          :state="$v.form.$anyDirty ? !$v.form.clinic_country.$anyError : null"
                        >
                          <b-form-select
                            v-model="form.clinic_country"
                            :options="countryList"
                            :state="$v.form.$anyDirty ? !$v.form.clinic_country.$anyError : null"
                            @change="onCountryChange"
                          >
                            <template #first>
                              <b-form-select-option :value="null">
                                Select Country
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                          <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.clinic_country.required : null">
                            country is required
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Telephone"
                        class="mb-6"
                        :state="$v.form.$anyDirty ? !$v.form.clinic_telphone.$anyError : null"
                      >
                        <b-form-input v-model="form.clinic_telphone" placeholder="Enter Telephone" />
                        <b-form-invalid-feedback
                          :state="$v.form.$anyDirty ? $v.form.clinic_telphone.required : null"
                        >
                          telephone is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Facility">
                        <b-form-select
                          v-model="form.facility"
                          :options="facilityList"
                          :state="$v.form.$anyDirty ? !$v.form.facility.$anyError : null"
                        >
                          <template #first>
                            <b-form-select-option :value="null">
                              Select Facility
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.facility.required : null">
                          facility is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="form.facility===99" cols="6">
                      <div class="mb-4">
                        <b-form-group label="Facility Detail">
                          <b-form-input v-model="form.facility_other" placeholder="Enter Facility Detail" />
                          <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.facility_other.req : null">
                            Facility Detail is required
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </template>

              <template v-else-if="step === 2">
                <h2 class="px-4">
                  Owner Information
                </h2>
                <hr>
                <div class="px-4">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Full Name"
                        class="mb-6"
                        :state="$v.form.$anyDirty ? !$v.form.name.$anyError : null"
                      >
                        <b-form-input v-model="form.name" placeholder="Enter Owner Name" />
                        <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.name.required : null">
                          owner name is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Email"
                        class="mb-6"
                        :state="$v.form.$anyDirty ? !$v.form.email.$anyError : null"
                      >
                        <b-form-input v-model="form.email" placeholder="Enter Email Address" />
                        <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.email.required : null">
                          email address is required
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.email.email : null">
                          email address format is incorrect
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.email.repeated : null">
                          email address is repeated
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Phone Extension (Optional)">
                        <b-form-input v-model="form.extension" placeholder="Default" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </template>

              <template v-else>
                <h2 class="px-4">
                  Product Information
                </h2>
                <hr>
                <div v-for="(autoclave, index) in autoclaveList" :key="index">
                  <div class="px-4">
                    <p class="h3 mb-2">
                      Autoclave {{ index + 1 }}
                      <font-awesome-icon
                        v-if="index !== 0"
                        :icon="['fas', 'trash']"
                        fixed-width
                        size="sm"
                        class="clinic-plus ml-3"
                        @click="removeAutoclave(index)"
                      />
                    </p>

                    <b-row>
                      <b-col cols="4">
                        <b-form-group
                          label="Model"
                          class="mb-6"
                          :state="$v.autoclaveList.$anyDirty ? !$v.autoclaveList.$each.$iter[index].model.$anyError : null"
                        >
                          <b-form-select
                            v-model="autoclave.model"
                            :options="modelList"
                            :state="$v.autoclaveList.$anyDirty ? !$v.autoclaveList.$each.$iter[index].model.$anyError : null"
                          >
                            <template #first>
                              <b-form-select-option :value="null">
                                Select model
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                          <b-form-invalid-feedback :state="$v.autoclaveList.$each.$iter[index].$anyDirty ? $v.autoclaveList.$each.$iter[index].model.required : null">
                            model is required
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="8">
                        <b-form-group
                          label="Serial Number"
                          class="mb-6"
                          :state="$v.autoclaveList.$anyDirty ? !$v.autoclaveList.$each.$iter[index].serial_number.$anyError : null"
                        >
                          <b-form-input v-model="autoclave.serial_number" placeholder="Enter Serial Number" />
                          <b-form-invalid-feedback :state="$v.autoclaveList.$each.$iter[index].$anyDirty ? $v.autoclaveList.$each.$iter[index].serial_number.required : null">
                            serial number is required
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback :state="$v.autoclaveList.$each.$iter[index].$anyDirty ? $v.autoclaveList.$each.$iter[index].serial_number.repeated : null">
                            serial number is repeated
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <b-form-group
                          label="Warranty"
                          class="mb-6"
                          :state="$v.autoclaveList.$anyDirty ? !$v.autoclaveList.$each.$iter[index].warranty.$anyError : null"
                        >
                          <b-form-datepicker
                            v-model="autoclave.warranty"
                            class="mb-2"
                            placeholder="Warranty Date"
                            :date-format-options="dateFormat"
                            :state="$v.autoclaveList.$anyDirty ? !$v.autoclaveList.$each.$iter[index].warranty.$anyError : null"
                          />
                          <b-form-invalid-feedback :state="$v.autoclaveList.$each.$iter[index].$anyDirty ? $v.autoclaveList.$each.$iter[index].warranty.required : null">
                            warranty is required
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col cols="8">
                        <b-form-group label="Autoclave Placement (Optional)">
                          <b-form-input v-model="autoclave.work_station" placeholder="Enter Autoclave Placement" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  <hr>
                </div>

                <div class="px-4">
                  <p class="h3 mb-0">
                    Autoclave {{ autoclaveList.length + 1 }}
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      fixed-width
                      size="sm"
                      class="clinic-plus ml-3"
                      @click="addAutoclave"
                    />
                  </p>
                </div>
              </template>
              <hr>
            </b-col>

            <b-col cols="3">
              <ul class="partner-list list-unstyled font-size-sm mt-16">
                <li class="partner-list-item active mb-4">
                  <span class="partner-list-number mr-2">1</span>
                  Customer Information
                </li>
                <li class="partner-list-item mb-4" :class="{ 'active': step > 1 }">
                  <span class="partner-list-number mr-2">2</span>
                  Owner Information
                </li>
                <li class="partner-list-item" :class="{ 'active': step > 2 }">
                  <span class="partner-list-number mr-2">2</span>
                  Product Information
                </li>
              </ul>
            </b-col>
          </b-row>

          <div class="d-flex px-4">
            <b-button variant="primary" class="btn-width mr-4" @click="nextStep">
              <template v-if="step === 3">
                SAVE
              </template>
              <template v-else>
                NEXT
              </template>
            </b-button>

            <b-button v-if="step > 1" variant="light" class="btn-width" @click="step -= 1">
              BACK
            </b-button>

            <b-button variant="light" class="btn-width ml-auto" :to="{ name: 'ClinicList' }">
              CANCEL
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <confirm-modal ref="confirmModal" @submit="submit" />
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import countryList, { getCountryCode } from '@/common/countryList'
import facilityList from '@/common/facilityList'
import modelList from '@/common/modelList'
import { createClinic } from '@/api/clinic'
import { createAutoclave } from '@/api/autoclave'
import ConfirmModal from '@/components/clinic/confirmModal'

export default {
  name: 'Create',
  components: {
    ConfirmModal
  },
  data () {
    return {
      step: 1,

      form: {
        clinic_name: '',
        clinic_address: '',
        clinic_city: null,
        clinic_country: null,
        clinic_telphone: '',
        facility: null,
        facility_other: '',

        name: '',
        email: '',
        extension: ''
      },

      autoclaveList: [],

      emailRepetList: [],
      autoclaveRepeatList: []
    }
  },
  validations () {
    return {
      form: {
        clinic_name: { required },
        clinic_address: { required },
        clinic_city: { required },
        clinic_country: { required },
        clinic_telphone: { required },
        facility: { required },
        facility_other: {
          req () {
            return this.form.facility !== 99 ||
            (this.form.facility === 99 && this.form.facility_other !== undefined && this.form.facility_other)
          }
        },

        name: { required },
        email: {
          required,
          email,
          repeated (value) {
            if (!this.form.email) return true
            return this.emailRepetList.indexOf(value) === -1
          }
        }
      },

      autoclaveList: {
        $each: {
          model: { required },
          serial_number: {
            required,
            repeated (value) {
              if (!value) return true
              if (this.autoclaveList.filter(a => a.serial_number === value).length > 1) {
                return true
              }
              return this.autoclaveRepeatList.indexOf(value) === -1
            }
          },
          warranty: {
            required
          }
        }
      },

      step1: [
        'form.clinic_name',
        'form.clinic_address',
        'form.clinic_city',
        'form.clinic_country',
        'form.clinic_telphone',
        'form.facility',
        'form.facility_other'
      ],
      step2: [
        'form.name',
        'form.email'
      ],
      step3: [
        'autoclaveList'
      ]
    }
  },
  computed: {
    countryList () {
      return countryList
    },
    facilityList () {
      return facilityList
    },
    modelList () {
      return modelList
    },
    dateFormat () {
      return { year: 'numeric', month: 'numeric', day: 'numeric' }
    }
  },
  created () {
    this.addAutoclave()
  },
  methods: {
    nextStep () {
      const key = `step${this.step}`
      this.$v[key].$touch()
      if (this.$v[key].$anyError) return
      if (this.step > 2) {
        this.openConfirmModal(this.autoclaveList)
      } else {
        this.$v.$reset()
        this.step += 1
      }
    },
    async submit () {
      const id = await this.createClinic()
      if (!id) return
      this.createAutoclave(id)
    },
    createClinic () {
      return createClinic(this.form).then(res => {
        if (res.data.success) {
          this.makeToast('Success', 'Create Clinic Success', 'success')
          return res.data.data.clinic.id
        } else {
          const errMsg = JSON.parse(res.data.error)
          if (errMsg.email !== undefined) {
            if (this.emailRepetList.indexOf(this.form.email) !== -1) return
            this.emailRepetList.push(this.form.email)
            this.step = 2
            this.$v[`step${this.step}`].$touch()
          } else {
            const msgs = Object.values(errMsg)
            msgs.forEach(m => this.makeToast('Create Clinic Fail', m, 'danger'))
          }

          return null
        }
      })
    },
    createAutoclave (id) {
      const data = {
        clinic_id: id,
        autoclaves: this.autoclaveList
      }
      createAutoclave(data).then(res => {
        if (res.data.success) {
          this.makeToast('Success', 'Create Autoclave Success', 'success')
          this.$router.push({ name: 'ClinicList' })
        } else {
          this.makeToast('Create Autoclave Fail', 'Serial Number(s) Duplicated', 'danger')
          const serialNumbers = res.data.data.serial_number
          this.autoclaveRepeatList = serialNumbers
        }
      })
    },
    addAutoclave () {
      const data = {
        model: null,
        serial_number: '',
        warranty: null,
        work_station: ''
      }
      this.autoclaveList.push(data)
    },
    removeAutoclave (index) {
      this.autoclaveList.splice(index, 1)
    },
    onCountryChange (value) {
      this.form.clinic_telphone = `+${getCountryCode(value)}`
    },
    openConfirmModal () {
      this.$refs.confirmModal.show(this.autoclaveList)
    },
    makeToast (title, msg, variant = null) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.partner-list {
  font-weight: 500;
  color: $gray-500;

  &-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.3rem;
    height: 1.3rem;
    color: #fff;
    background-color: $gray-500;
    border-radius: 50%;
    padding-bottom: 2px;
  }

  &-item {
    display: flex;
    align-items: center;

    &.active {
      color: $primary;

      .partner-list-number {
        background-color: $primary;
      }
    }
  }
}

.clinic-plus {
  cursor: pointer;
  color: #aeaec0;
}
</style>
